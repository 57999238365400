import React, { createContext } from "react";

const ThemeContext = createContext();
export const wrapRootElement = ({ element }) => {
  let theme = "dark";
  try {
    theme = localStorage.getItem("data-theme");
    // console.log(theme);
  } catch {}

  return <ThemeContext.Provider value={theme}>{element}</ThemeContext.Provider>;
};
