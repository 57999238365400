exports.components = {
  "component---src-components-templates-page-templates-case-study-template-js": () => import("./../../../src/components/Templates/PageTemplates/CaseStudyTemplate.js" /* webpackChunkName: "component---src-components-templates-page-templates-case-study-template-js" */),
  "component---src-components-templates-page-templates-post-template-js": () => import("./../../../src/components/Templates/PageTemplates/PostTemplate.js" /* webpackChunkName: "component---src-components-templates-page-templates-post-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-design-blog-js": () => import("./../../../src/pages/design-blog.js" /* webpackChunkName: "component---src-pages-design-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

